@import "@/scss/_variables.scss";

























































.page {
  width: 100%;
  height: 100%;
}

main {
  max-width: $content-width;
  width: calc(100% - 16px * 2);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 33px;

  .page-header + & {
    padding-top: 113px;
  }
}

.prevent-scroll {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
