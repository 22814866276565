@import "@/scss/_variables.scss";























































































.page-header {
  position: fixed;
  z-index: 2;
  width: 100%;
  max-width: 100%;
  height: $height-header;

  background-color: #FFFFFF;
  box-shadow: 0px 1px 0px #CAD3D9;

  &.mobile {
    height: $height-header-mobile;

    .page-header-inner {
      justify-content: initial;
    }

    .page-header-logo {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      a {
        display: flex;
        width: 100%;
        height: 100%;

        justify-content: center;
      }
    }

    .page-header-nav {
      transform: translateX(0);

      .page-header-hamburger {
        height: $height-header-mobile;

        box-shadow: 0px 1px 0px #CAD3D9;
      }

      nav {
        height: auto;
        padding: 16px 16px 0 16px;

        ul {
          flex-direction: column;
          gap: 0;

          li {
            height: auto;

            border-bottom: 1px solid #CAD3D9;

            a {
              justify-content: initial;
              padding: 16px 0 16px 0;
              font-size: 1.313rem;
            }

            &.active {
              &:after {
                content: initial;
              }
            }
          }
        }
      }
    }

    .page-header-hamburger {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      .hamburger {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .hamburger-text {
        margin-top: 10px;

        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }

    &.navbar-open {
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(41,49,54,0.6);
      }

      .page-header-nav {
        transform: translateX(0);

        .page-header-hamburger {
          height: $height-header-mobile;

          box-shadow: 0px 1px 0px #CAD3D9;
        }

        nav {
          height: auto;
          padding: 16px 16px 0 16px;

          ul {
            flex-direction: column;
            gap: 0;

            li {
              height: auto;

              border-bottom: 1px solid #CAD3D9;

              a {
                justify-content: initial;
                padding: 16px 0 16px 0;
                font-size: 1.313rem;
              }

              &.active {
                &:after {
                  content: initial;
                }
              }
            }
          }
        }
      }
    }

    .page-header-nav {
      position: absolute;
      left: 0;
      top: 0;
      height: 100vh;
      width: 85%;

      transition: 0.3s transform;
      overflow-y: auto;
      background: #FFFFFF;
      color: #293136;
      transform: translateX(-100%);

      &.is-open {
        transform: translateX(0);
      }
    }

    .page-header-other {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      visibility: hidden;
    }
  }

  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    max-width: $content-width;
    width: calc(100% - var(--layout-content-padding, 20px) * 2) !important;
    margin: auto;

    font-family: futura-pt-condensed, sans-serif;
    font-weight: bold;

    .page-header-logo {
      img {
        padding: 8px 0 8px 0;
        max-height: 100%;
      }
    }

    .page-header-nav {
      nav {
        height: 100%;

        ul {
          display: flex;
          flex-direction: row;
          gap: 32px;
          height: 100%;

          li {
            height: 100%;

            &:hover {
              color: #E3000B;
            }

            &.active {
              position: relative;

              color: #E3000B;

              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;

                background-color: #E3000B;
              }
            }

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              img {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    .page-header-other {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
          margin-left: 8px;
        }
      }
    }
  }
}
