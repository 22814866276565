@import "@/scss/_variables.scss";
@import url('https://use.typekit.net/nye0wat.css');

html, body {
  font-family: futura-pt, sans-serif;
  font-size: 21px;

  @media (max-width: $width-smartphone) {
    font-size: 16px;
  }

  background-color: #F5F6F7;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-justify {
  text-align: justify;
}

p {
  margin-bottom: 1.5em;
  line-height: 1.5em;
}

a {
  &.link-button {
    font-weight: bold;
  }

  &.download-button {
    font-weight: bold;
  }
}

span {
  &.js-gaOptOut {
    font-weight: bold;
  }

  &.js-fbOptOut {
    font-weight: bold;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: futura-pt-condensed, sans-serif;

  strong {
    font-weight: bold;
  }
}

h1, .h1 {
  font-size: 2.9rem;
  line-height: 1.35rem;
}

h2, .h2 {
  font-size: 1.75rem;
  line-height: 1.35rem;
}

h3, .h3 {
  font-size: 1.34rem;
  line-height: 1.35rem;
}

h4, .h4 {
  font-size: 1.2rem;
  line-height: 1.35rem;
}

h5, .h5 {
  font-size: 1.1rem;
  line-height: 1rem;
}

h6, .h6 {
  font-size: 0.762rem;
  line-height: 0.762rem;

  @media (max-width: $width-tablet) {
    font-size: 1rem;
  }
}

input[type="number"] {
  width: 100%;
  padding: 13px 16px;

  background-color: #FFFFFF;
  border: 1px solid #CAD3D9;

  font-family: futura-pt-book, sans-serif;
  font-size: 0.762rem;
}

select {
  padding: 8px 7px 8px 0;
  border: none;
  background-color: white;
}

p {
  &.info {
    margin: 0;
    padding: 0;

    color: #69777F;
    font-size: 0.571rem;
    line-height: 0.571rem;
  }
}