@import "@/scss/_variables.scss";
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}